export const environment = {
  name: 'development',
  production: false,
  origin: 'https://dev.coach.toptracer.com/',
  apiUrl: 'https://dev.game-data.toptracer.com/',
  baseUrl: '/',
  auth: {
    issuer: 'https://dev.login.toptracer.com/realms/toptracer/',
    clientId: 'coach-dev',
  },
  websterUrl: 'https://dev.webster.toptracer.com/',
  websterToken: 'Bearer 14c5113a-a899-8640-6586-b0da4b74ac1e',
};
